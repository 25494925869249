import moment from "moment";
import React, { useEffect, useState } from "react";
import { injectIntl, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { scrollSpy } from "react-scroll";
import Swal from "sweetalert2";
import { apiService } from "../../../common/api";
import db from "../../../common/db";
import { ToastNotificationError } from "../../../components";
import { ModalComponent } from "../../../components/common/modal";
import { default as Navbar } from "../../../components/common/navbar";
import EventVisibleBy from "../../../components/event/EventVisibleBy";
import SectionsCreationModal from "../../../components/event/MoreDetailsCreationModal/SectionsCreationModal";
import EventHeader from "../../../components/event/eventHeader";
import EventNavigation from "../../../components/event/eventNavigation";
import { IMAGES, ROUTES } from "../../../constant";
import { getBasicInfo, getOrganization } from "../../../utils";
import * as commonActions from "../../common/redux/actions";
import * as organizationActions from "../../organization/redux/actions";
import * as actions from "../redux/actions";
import { EventDetail } from "./EventDetail";

const EventDetailContainer = (props) => {
  const { match, weatherDetail } = props;
  const calendarEvents = useSelector((state) => state.calendar.calendarEvents);
  const event = useSelector((state) => state.event);
  const eventsList = useSelector((state) => state.calendar.calendarEvents);
  // const teams = useSelector((state) => state.organizations.teams);
  const connection = useSelector((state) => state.meet.connection);
  const connectedUsers = useSelector((state) => state.meet.connectedUsers);

  const [state, setState] = useState({
    hotels: {},
    hotelSelected: 0,
    flights: {},
    flightsSelected: 0,
    contactsSelected: 0,
    itineraries: {},
    itinerariesSelected: 0,
    latitude: "",
    longitude: "",
    guid: "",
    loading: false,
    eventCoordinates: {},
    currentSideBarItem: "EVENT_DETAILS",
    currentSideBarItemId: 0,
    sideBarItemData: {},
    visibiltityModal: false,
    eventStaffs: {},
    checkImage: null,
    selectStaffsAll: false,
    eventStaffTeams: {},
    searchedStaff: {},
    initialCheckImage: null,
    selectedOrganizationId: null,
    showEventsSidebar: false,
    staffSelected: [],
    places: [],
    productionModal: false,
    advancedDetails: false,
    layout: "EVENT",
    venueModal: false,
    editVenueModal: false,
    currentElement: "",
    sectionVisibility: [
      "hotel",
      "itineraries",
      "flights",
      "ground",
      "contact",
      "attachment",
    ],
    currentEvent: 0,
    previousEvent: {},
  });

  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  const intl = useIntl();

  const currentIndex = calendarEvents.findIndex(
    (e) => e.GUID === event.event.GUID
  );

  const [prevNextState, setPrevNextState] = useState({
    prev: false,
    next: false,
  });

  useEffect(() => {
    localStorage.setItem("CURRENT_EVENT_GUID", match.params.guid);
    let currentOrg = getOrganization().GUID;
    let basicInfoGUID = getBasicInfo().GUID;

    if (calendarEvents.length === 0) {
      dispatch(actions.getEvents(currentOrg, basicInfoGUID));
    }

    dispatch(
      actions.eventDetail(
        match.params.guid,
        () =>
          ToastNotificationError(1, "Error fetching event/venue, try again"),
        () => redirectToCalendar(event.event)
      )
    );

    dispatch(actions.getScheduleMasters(match.params.guid));
    dispatch(actions.getAttachmentsEvent(match.params.guid));
    dispatch(actions.getContacts(match.params.guid));
    dispatch(actions.getGrounds(match.params.guid));
    dispatch(actions.getHotel(match.params.guid));
    dispatch(actions.getFlights(match.params.guid));
    dispatch(
      organizationActions.getCurrentOrganization(currentOrg, basicInfoGUID)
    );
    dispatch(actions.getVisibles(match.params.guid));
    dispatch(actions.getTemplates(getOrganization().GUID));
    dispatch(
      organizationActions.getOrganizationList(getBasicInfo().GUID, true)
    );

    dispatch(commonActions.setActiveNotes());
    scrollSpy.update();

    return () => {
      dispatch(actions.cleanEvent());
      removeLastScroll();
    };
  }, []);

  useEffect(() => {
    recordHistoricalVisitOfEvent(event.event);
    setCurrentDayOnCalendar(event.event);
  }, [event.event]);

  useEffect(() => {
    setPrevNextState({
      prev:
        calendarEvents[Math.abs(currentIndex) - 1] === null ||
        calendarEvents[Math.abs(currentIndex) - 1] === undefined
          ? false
          : true,
      next:
        calendarEvents[Math.abs(currentIndex) + 1] === null ||
        calendarEvents[Math.abs(currentIndex) + 1] === undefined
          ? false
          : true,
    });
  }, [calendarEvents, currentIndex]);

  function scrollToElement(location) {
    if (!location) return;
    const listSection = document.getElementById("listSection");
    listSection?.querySelector(location)?.scrollIntoView();
  }

  // function getLastScroll() {
  //   const hash = location.hash;
  //   const listSection = document.getElementById("listSection");
  //   let scrollpos = localStorage.getItem("scrollpos");
  //   if (scrollpos && listSection) listSection.scrollTop = scrollpos;
  // }

  // function setLastScroll() {
  //   const listSection = document.getElementById("listSection");
  //   const setScrollOps = () => {
  //     localStorage.setItem("scrollpos", listSection.scrollTop);
  //   };
  //   listSection?.addEventListener("scroll", setScrollOps);
  // }

  function removeLastScroll() {
    localStorage.removeItem("scrollpos");
  }

  function toggleEditVenue() {
    setState((prev) => {
      return {
        ...prev,
        editVenueModal: !prev.editVenueModal,
      };
    });
  }

  function toggleVenueModal() {
    setState((prev) => {
      return {
        ...prev,
        venueModal: !prev.venueModal,
      };
    });
  }

  async function setCurrentDayOnCalendar(event) {
    const basicInfoGUID = getBasicInfo().GUID;

    try {
      const appSettings = await db
        .table("appSettings")
        .where({ AccountGUID: basicInfoGUID })
        .first();

      if (appSettings) {
        await db.table("appSettings").put({
          ...appSettings,
          currentDayOnCalendar: event?.Date || event?.lastDate,
        });
      } else {
        await db.table("appSettings").put({
          AccountGUID: basicInfoGUID,
          currentDayOnCalendar: event?.Date || event?.lastDate,
        });
      }
    } catch (error) {
      console.log({ error, errorMessage: "Error while saving calendar Date" });
    }
  }

  function redirectToCalendar() {
    if (event.OrganizationGUID !== getOrganization().GUID) {
      // history.push("/calendar");
      // ToastNotificationError(
      //   1,
      //   "This event is not from your current organization"
      // );
    }
  }

  function refreshSchedules() {
    dispatch(actions.getScheduleMasters(match.params.guid));
  }

  function toggleSidebarEvents() {
    setState((prev) => {
      return {
        ...prev,
        showEventsSidebar: !prev.showEventsSidebar,
      };
    });
  }

  function toggleProductionModal(value) {
    if (event !== undefined && event !== null) {
      // setState((prev) => {
      //   return {
      //     ...prev,
      //     productionModal: !prev.productionModal,
      //   };
      // });

      setState((prev) => {
        return {
          ...prev,
          advancedDetails: value,
        };
      });
    }
  }

  // function toggleMember(staff) {
  //   let staffs = state.staffSelected;
  //   if (staffs.includes(staff.GUID)) {
  //     let filtered = staffs.filter((s) => s !== staff.GUID);
  //     return setState((prev) => {
  //       return {
  //         ...prev,
  //         staffSelected: filtered,
  //       };
  //     });
  //   } else {
  //     let newOnes = staffs.concat(staff.GUID);
  //     return setState((prev) => {
  //       return {
  //         ...prev,
  //         staffSelected: newOnes,
  //       };
  //     });
  //   }
  // }

  // function toggleDeleted(staff) {
  //   let staffs = state.staffSelected;
  //   staffs.map((s) => {
  //     if (s.StaffGUID === staff.GUID) {
  //     } else {
  //     }
  //     return s;
  //   });
  // }

  function handleSidebarItem(currentSideBarType, data) {
    setState((prev) => {
      return {
        ...prev,
        currentSideBarItem: currentSideBarType,
        sideBarItemData: data,
      };
    });
  }

  function onRefreshStatus() {
    if (state.staffSelected.length === 0) {
      setState((prev) => {
        return {
          ...prev,
          staffSelected: event.visibles,
        };
      });
    }
  }

  function toggleVisibilty() {
    onRefreshStatus();
    setState((prev) => {
      return {
        ...prev,
        visibiltityModal: !prev.visibiltityModal,
      };
    });
  }

  function reloadData() {
    dispatch(
      actions.eventDetail(
        match.params.guid,
        () =>
          ToastNotificationError(1, "Error fetching event/venue, try again"),
        () => redirectToCalendar(event.event),
        true
      )
    );
    localStorage.setItem("CURRENT_EVENT_GUID", match.params.guid);
    dispatch(actions.getScheduleMasters(match.params.guid));
    dispatch(actions.getAttachmentsEvent(match.params.guid));
    dispatch(actions.getContacts(match.params.guid));
    dispatch(actions.getGrounds(match.params.guid));
    dispatch(actions.getHotel(match.params.guid));
    dispatch(actions.getFlights(match.params.guid));
    dispatch(actions.getVisibles(match.params.guid));
    setCurrentDayOnCalendar();
    handleSidebarItem("EVENT_DETAILS", {});
  }

  // function handleVisibility() {
  //   let staffsArray = state?.staffSelected.map((staff) => ({
  //     EventGUID: event.event.GUID,
  //     StaffGUID: staff,
  //     GUID: uuid.v4(),
  //     Deleted: null,
  //   }));
  // }

  // function dispatchLoadData(existNext) {
  //   dispatch(
  //     actions.eventDetail(
  //       existNext.GUID,
  //       () =>
  //         ToastNotificationError(1, "Error fetching event/venue, try again"),
  //       () => redirectToCalendar(event.event),
  //       true
  //     )
  //   );
  //   dispatch(actions.getScheduleMasters(match.params.guid));
  //   dispatch(actions.getAttachmentsEvent(match.params.guid));
  //   dispatch(actions.getContacts(match.params.guid));
  //   dispatch(actions.getGrounds(match.params.guid));
  //   dispatch(actions.getHotel(match.params.guid));
  //   dispatch(actions.getFlights(match.params.guid));
  //   dispatch(actions.getVisibles(match.params.guid));
  // }

  function prevEvent() {
    // const currentIndex = calendarEvents.findIndex(
    //   (e) => e.GUID === event.event.GUID
    // );

    if (currentIndex > 0) {
      const existPrev = calendarEvents[currentIndex - 1];
      history.push(`/calendar/${existPrev.GUID}`);
      // reloadData();
      window.location.reload();
    } else {
    }
  }

  function nextEvent() {
    // const currentIndex = calendarEvents.findIndex(
    //   (e) => e.GUID === event.event.GUID
    // );

    if (currentIndex < calendarEvents.length - 1) {
      const existNext = calendarEvents[currentIndex + 1];
      history.push(`/calendar/${existNext.GUID}`);
      // reloadData();
      window.location.reload();
    }
  }

  async function recordHistoricalVisitOfEvent(theEvent) {
    theEvent.lastDate = moment().format();
    try {
      if (theEvent.GUID) {
        await db.table("eventHistory").put(theEvent);
      }
    } catch (error) {
      console.log({
        error,
        errorMessage: "Error while saving navigation history.",
      });
    }
  }

  function deleteVenue() {
    Swal.fire({
      text: "Are you sure you want to remove this venue?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(actions.deleteVenue(event.event));
        Swal.fire("Deleted!", "The venue has been removed", "success");
      }
    });
  }

  function deleteEvent() {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this event?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteEvent({ ...event.event, Deleted: new Date() }, () =>
            history.push(ROUTES.CALENDAR)
          )
        );
      }
    });
  }

  // function scrollTo(name) {
  //   scroller.scrollTo(name, {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // }

  // function handleActiveModal(name) {}

  function handleNavigationSelect(name) {
    const propNames = {
      general: "",
      itineraries: "schedulesMasters",
      flights: "flights",
      hotels: "hotels",
      transportation: "transportation",
      contacts: "contacts",
      attachments: "attachments",
    };

    const sideBarSetter = {
      general: "EVENT_DETAILS",
      itineraries: "ACTIVITIES",
      flights: "FLIGHT_LIST",
      hotels: "HOTEL",
      transportation: "GROUND",
      contacts: "CONTACTS",
      attachments: "ATTACHMENT",
    };

    const dataSetter = sideBarSetter[name];
    const eventData =
      dataSetter === "EVENT_DETAILS"
        ? {}
        : event[
            propNames[name] === "transportation" ? "grounds" : propNames[name]
          ]?.filter((item) => item.Deleted === null)[0];

    // if (dataSetter === state.currentSideBarItem) return;

    if (eventData) {
      handleSidebarItem(dataSetter, eventData);
    } else {
      handleSidebarItem("EVENT_DETAILS", {});

      // handleActiveModal(name);
    }

    setState((prev) => {
      return {
        ...prev,
        currentElement: name,
      };
    });
  }

  let hotelPlaces = event.hotels.map((h) => ({
    Name: h.Name,
    Address: h.Address,
    Latitude: h.Latitude,
    Longitude: h.Longitude,
  }));
  let venuePlace = {
    Name: event.event.Venue && event.event.Venue.Name,
    Address: event.event.Venue && event.event.Venue.Address,
    Latitude: event.event.Venue && event.event.Venue.Latitude,
    Longitude: event.event.Venue && event.event.Venue.Longitude,
  };

  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const [selected, setSelected] = useState("");

  const handleScroll = (eventDetails, sections) => {
    const offset = eventDetails?.scrollTop;
    let current = "";
    for (const section of sections) {
      const sectionTop = section.offsetTop;
      if (sectionTop >= offset - 200) {
        current = section.getAttribute("id");
        setSelected(current);
        handleNavigationSelect(current);
        break;
      }
    }
  };

  const debouncedScrollHandler = debounce(() => {
    const eventDetails = document.getElementById("listSection");
    const sections = [];

    eventDetails?.childNodes?.forEach((element) => {
      if (element.getAttribute("id")) sections.push(element);
    });

    handleScroll(eventDetails, sections);
  }, 200);

  useEffect(() => {
    if (event.loading || event.loadingEvent || event.loadingFlights) return;

    const eventDetails = document.getElementById("listSection");
    eventDetails?.addEventListener("scroll", debouncedScrollHandler);

    return () => {
      eventDetails?.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, [event]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 560);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 560);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleNext = async () => {
    // console.log({ event });
    let next = nextEvent();
    // setPrevNextState((prevState) => {
    //   return { prev: false, next };
    // });
  };
  const handlePrevious = async () => {
    let prev = prevEvent();
    // setPrevNextState((prevState) => {
    //   return { next: false, prev };
    // });
  };

  const [menuOptions, setMenuOptions] = useState([]);

  const fetchNavigationOptions = async () => {
    try {
      let response = await apiService.get(
        `/eventblock/list?eventguid=${event.event.GUID}`
      );
      if (response.status === 200) {
        const sortedResponse = response.data.Response.sort(
          (a, b) => a.Order - b.Order
        ).map((item) => {
          return item.Name === "Event Contact"
            ? {
                ...item,
                Name: "Contacts",
              }
            : item;
        });
        setMenuOptions(sortedResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (event.event.GUID) {
      fetchNavigationOptions();
    }
  }, [event]);

  const [openNewMenuModal, setOpenNewMenuModal] = useState(false);
  const [newMenuOption, setNewMenuOption] = useState({
    Name: "",
    Icon: "",
  });

  const toggleNewMenuModal = () => {
    setOpenNewMenuModal(!openNewMenuModal);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setNewMenuOption({ ...newMenuOption, [e.target.name]: e.target.value });
  };

  const handleCreateNewSection = async (name, icon) => {
    // setIsLoading(true);
    // try {
    //   const newOption = {
    //     Name: name,
    //     Icon: icon,
    //     Id: name.toLocaleLowerCase(),
    //     EventId: event.event.GUID,
    //     BlockType: name.toLocaleLowerCase(),
    //     Order: menuOptions ? menuOptions.length + 1 : 1,
    //   };

    //   let response = await apiService.post("/eventblock/addedit", newOption);
    //   if (response.status === 200) {
    //     fetchNavigationOptions();
    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const deleteSection = async (id) => {
    try {
      let response = await apiService.get(`/eventblock/delete?id=${id}`);
      console.log({
        response,
      });
      if (response.status === 200) {
        fetchNavigationOptions();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div id="eventNavigation" className="eventNavigation__header ">
        <SectionsCreationModal
          eventGUID={event.event.GUID}
          toggleModal={toggleNewMenuModal}
          showModal={openNewMenuModal}
          sections={menuOptions}
          handleCreateNewSection={handleCreateNewSection}
          deleteSection={deleteSection}
          fetchNavigationOptions={fetchNavigationOptions}
          handleAddDocument={(file, extension) => {
            // createFile(file, extension);
            // toggleCreateNewFile();
          }}
          // createFileFromTemplate={createFileFromTemplate}
          handleAddSheet={() => {
            // createFile();
            toggleNewMenuModal();
          }}
          handleAddPDF={() => {
            // createFile();
            toggleNewMenuModal();
          }}
          handleUploadFile={() => {
            // createFile();
            toggleNewMenuModal();
          }}
        />

        <EventHeader
          event={event.event}
          loading={event.loading}
          toggleVisibilty={toggleVisibilty}
          toggleSidebarEvents={toggleSidebarEvents}
          showEventsSidebar={state.showEventsSidebar}
          productionModal={state.productionModal}
          toggleProductionModal={toggleProductionModal}
          eventsList={eventsList}
          history={history}
          prevEvent={prevEvent}
          nextEvent={nextEvent}
          handleSidebarItem={handleSidebarItem}
          venuePlace={venuePlace}
          hotelPlaces={hotelPlaces}
          addNote={actions.addNote}
          notes={event.notes}
          toggleLastSync={actions.toggleLastSync}
          sendEmail={commonActions.sendEmail}
          connection={connection}
          connectedUsers={connectedUsers}
          deleteEvent={deleteEvent}
          weather={event.weather}
          previousEvent={state.previousEvent}
          isMobile={isMobile}
          expanded={props.expanded}
          showExpanded={props.showExpanded}
          prevNextState={prevNextState}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
        <EventNavigation
          handleSidebarItem={handleSidebarItem}
          currentElement={state.currentElement}
          toggleNewMenuModal={toggleNewMenuModal}
          layout={state.layout}
          event={event.event}
          loading={event.loading}
          options={menuOptions}
          toggleProductionModal={toggleProductionModal}
          handleNavigationSelect={handleNavigationSelect}
          history={history}
          scrollToElement={scrollToElement}
          selected={selected}
          setSelected={setSelected}
          isMobile={isMobile}
        />
      </div>

      <EventDetail
        // teams={teams}
        menuOptions={menuOptions}
        currentSideBarItem={state.currentSideBarItem}
        schedulesMasters={event.schedulesMasters}
        attachments={event.attachments}
        contacts={event.contacts}
        hotels={event.hotels}
        grounds={event.grounds}
        flights={event.flights}
        venuesList={event.venues}
        weatherDetail={weatherDetail}
        event={event.event}
        loading={event.loading}
        venue={event.eventVenue}
        addActivity={actions.addActivity}
        addContact={actions.addContact}
        addAttachment={actions.addAttachment}
        getAttachmentsEvent={actions.getAttachmentsEvent}
        addItinerary={actions.addItinerary}
        editAttachment={actions.editAttachment}
        deleteAttachment={actions.deleteAttachment}
        deleteItinerary={actions.deleteItinerary}
        deleteActivity={actions.deleteActivity}
        deleteContact={actions.deleteContact}
        addGround={actions.addGround}
        addHotel={actions.addHotel}
        show={state.show}
        setVenue={actions.setVenue}
        match={match}
        handleSidebarItem={handleSidebarItem}
        toggleSidebarEvents={toggleSidebarEvents}
        showEventsSidebar={state.showEventsSidebar}
        eventsList={eventsList}
        sideBarItemData={state.sideBarItemData}
        deleteGround={actions.deleteGround}
        deleteHotel={actions.deleteHotel}
        addAttachmentDropbox={actions.addAttachmentDropbox}
        editActivity={actions.editActivity}
        refreshSchedules={refreshSchedules}
        clearSchedules={actions.clearSchedules}
        removeAllReservations={actions.removeAllReservations}
        getHotel={actions.getHotel}
        weather={event.weather}
        toggleVenueModal={toggleVenueModal}
        venueModal={state.venueModal}
        editVenueModal={state.editVenueModal}
        toggleEditVenue={toggleEditVenue}
        deleteVenue={deleteVenue}
        handleNavigationSelect={handleNavigationSelect}
        setEventDetailsState={setState}
        scrollToElement={scrollToElement}
        advancedDetails={state.advancedDetails}
      />
      <ModalComponent
        toggle={toggleVisibilty}
        modal={state.visibiltityModal}
        childeren={
          <EventVisibleBy event={event.event} toggle={toggleVisibilty} />
        }
      />
    </>
  );
};

export default Navbar(injectIntl(EventDetailContainer));
